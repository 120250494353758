import classes from "./ProjectDiv.module.css";
import { useState } from "react";
import { motion } from "framer-motion";

const ProjectDiv = (props) => {
    const [isHovered,setIsHovered] = useState(false);

    const cssStyle =
    {
        backgroundImage: `linear-gradient(rgba(0, 0, 0,0.5), rgba(28, 171, 217,0.5)), url(${props.image})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        pointerEvents: props.disable && "none",
    }

    const hoverCssStyle =
    {
        backgroundImage: `url(${props.image})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        cursor: "pointer",
        transition: "0.6s ease-in-out"
    }

    const mouseEnterHandler = () => {
        setIsHovered(true);
    };


    const mouseLeaveHandler = () => {
        setIsHovered(false);
    };

    return (
        <motion.div 
            className={classes.ProjectDiv}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            animate={props.isAnimated ? cssStyle: {rotate: "360deg"}}
            transition={{duration: 0.5}}
            style={isHovered ? hoverCssStyle: cssStyle}
            onClick={props.onClick}>
            <span 
                className={classes.AppName}
                style={{display: isHovered ? "none":"block"}}>{props.appName}</span>
            <p 
                className={classes.AppDescription} 
                style={{display: isHovered ? "none":"inline"}}>
                {props.description}</p>    
        </motion.div >
    );
};

export default ProjectDiv;