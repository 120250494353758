import classes from "./LanguageBar.module.css";

const LanguageBar = (props) => {
    let cssClasses = classes.LanguageBar;
    if(props.active)
    {
        cssClasses = classes.LanguageBar + " "+classes.active;
    }
    return (
        <button 
        className={cssClasses}
        onClick={props.onClick}
        disabled={props.active}
        >{props.language}
        </button>
    );
};

export default LanguageBar;