import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Container from "../UI/Container";
import classes from "./BlogLayout.module.css";

const BlogLayout = () =>{
    return (
        <Container>
            <Navbar />
            <div className={classes.PageDiv}>
                <Outlet />
            </div>
        </Container>
    );
} ;

export default BlogLayout;