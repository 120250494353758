import classes from "./Fact.module.css";

const Fact = (props) =>{
    let wallpaperClasses = classes.FactDiv;
    if(props.type==="software engineer")
    {
        wallpaperClasses += ` ${classes.SoftwareEngineeringWallpaper}`;
    }
    else if(props.type==="web developer")
    {
        wallpaperClasses += ` ${classes.WebDevelopmentWallpaper}`;
    }
    else if(props.type==="mobile developer")
    {
        wallpaperClasses += ` ${classes.MobileDevelopmentWallpaper}`;
    }
    else if(props.type==="team player")
    {
        wallpaperClasses += ` ${classes.TeamPlayerWallpaper}`;
    }

    return (
        <div className={classes.FactDivContainer}>
            <div className={wallpaperClasses}></div>
            <p className={classes.FactName}>{props.text}</p>
        </div>
    );
};

export default Fact;