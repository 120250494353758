import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    BlogLanguage: "TR",
    CurrentPage: "home",
    token: null,
    isAuthenticated: false,
};

const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers:
    {
        setBlogLanguage(state,action)
        {
            state.BlogLanguage = action.payload;
        },
        setCurrentPage(state,action)
        {
            state.CurrentPage = action.payload;
        },
        setToken(state,action)
        {
            state.token = action.payload;
        },
        setIsAuthenticated(state,action)
        {
            state.isAuthenticated = action.payload;
        }
    }
});

export const blogActions = blogSlice.actions;
export default blogSlice;