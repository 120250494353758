import classes from "./AboutPage.module.css";
import { useDispatch,useSelector } from "react-redux";
import { blogActions } from "../Store/BlogSlice";
import FeatureLabel from "../Components/About/FeatureLabel";
import Fact from "../Components/About/Fact";
import { motion } from "framer-motion";
import { useState,useEffect } from "react";

const AboutPage = () => {
    const BlogLanguage = useSelector(state=>state.BlogLanguage);
    const [isAnimated,setIsAnimated] = useState(false);
    const TurkishLanguage = 
    {
        aboutHeader: "Hakkımda",
        aboutDescription: "Kod evrenime hoşgeldiniz. Ben, dijital çözümler oluşturmanın sanatını öğrenme misyonu üzerine yola çıkmış tutkulu bir yazılım mühendisliği öğrencisiyim. Çeşitli programlama dillerinde ve en yeni teknolojilerde uzmanlaşmış biriyim. İşbirliği benim alanım, çünkü en iyi çözümler zihinler birleştikçe ortaya çıkar.",
        jobDescription: "Yazılım Mühendisi & Full Stack Geliştirici",
        birthDayFieldName: "Doğum Günü",
        birthDayFieldValue: "18 Mart 2002",
        townCityFieldName: "İlçe/İl",
        townCityFieldValue: "Kartal/İstanbul",
        phoneFieldName: "Telefon",
        degreeFieldName: "Eğitim",
        degreeFieldValue: "Yazılım Mühendisliği",
        freelanceFieldName: "Serbest Çalışma",
        freelanceFieldValue: "Uygun",
        softwareEngineerText: "Yazılım Mühendisi",
        webDeveloperText: "Web Geliştirici",
        mobileDeveloperText: "Mobil Geliştirici",
        teamPlayerText: "Takım Oyuncusu"
    };

    const [context,setContext] = useState(TurkishLanguage);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(blogActions.setCurrentPage("about"));
        setIsAnimated(true);
        const timeoutId = setTimeout(() => {
            setIsAnimated(false);
        }, 300);
        if(BlogLanguage==="EN")
        {
            setContext({
                aboutHeader: "About",
                aboutDescription: 
                "Welcome to my corner of the coding universe! I'm a passionate software engineering student on a mission to master the art of creating digital solutions. Fueled by curiosity and a love for challenges, I specialize in diverse programming languages and cutting-edge technologies. Collaboration is my forte, as I believe the best solutions emerge when minds unite.",
                jobDescription:
                "Software Engineer & Full Stack Developer",
                birthDayFieldName: "Birthday",
                birthDayFieldValue: "March 18 2002",
                townCityFieldName: "Town-City",
                townCityFieldValue: "Kartal/Istanbul",
                phoneFieldName: "Phone",
                degreeFieldName: "Degree",
                degreeFieldValue: "Bachelor (Continue)",
                freelanceFieldName: "Freelance",
                freelanceFieldValue: "Available",
                softwareEngineerText: "Software Engineer",
                webDeveloperText: "Web App Developer",
                mobileDeveloperText: "Mobile App Developer",
                teamPlayerText: "Team Player"
            });
        }
        else
        {
            setContext(TurkishLanguage);
        }
        return () => clearTimeout(timeoutId);

        // eslint-disable-next-line
    },[BlogLanguage,dispatch]);

    const variants = {
        upperInitial:{ y: -100, opacity: 0 },
        upperAnimate:{ y: 0, opacity: 1 },
        bottomInitial: { y: "100%", opacity: 0 },
        bottomAnimate: { y: 0, opacity: 1 }
    };

    return (
        <div className={classes.AboutPageDiv}>
            <div className={classes.AboutPageInnerDiv}>
                <motion.h2 
                className={classes.AboutHeader}
                initial={variants.upperInitial}
                animate={isAnimated ? variants.upperInitial : variants.upperAnimate}
                exit={variants.upperInitial}>{context.aboutHeader}</motion.h2>
                <motion.p 
                className={classes.AboutDescription}
                initial={variants.upperInitial}
                animate={isAnimated ? variants.upperInitial : variants.upperAnimate}
                exit={variants.upperInitial}>{context.aboutDescription}</motion.p>
                <div className={classes.AboutInfoDiv}>
                    <div className={classes.PersonelInfo}>
                        <h2 className={classes.JobDescription}>{context.jobDescription}</h2>
                            <FeatureLabel 
                                fieldName={context.birthDayFieldName} 
                                fieldValue={context.birthDayFieldValue} />
                            <FeatureLabel 
                                fieldName={context.townCityFieldName}
                                fieldValue={context.townCityFieldValue} />
                            <FeatureLabel 
                                fieldName="Email" 
                                fieldValue="mustafacil198@gmail.com" />
                            <FeatureLabel 
                                fieldName={context.phoneFieldName} 
                                fieldValue="+90 0537 388 83 15" />
                            <FeatureLabel 
                                fieldName={context.degreeFieldName} 
                                fieldValue={context.degreeFieldValue} />
                            <FeatureLabel 
                                fieldName={context.freelanceFieldName} 
                                fieldValue={context.freelanceFieldValue} />     
                    </div>
                </div>
                <motion.div 
                className={classes.FactsDiv}
                initial={variants.bottomInitial}
                animate={isAnimated ? variants.bottomInitial : variants.bottomAnimate}
                exit={variants.bottomInitial}>
                    <Fact 
                        text={context.softwareEngineerText} 
                        type="software engineer" />
                    <Fact 
                        text={context.webDeveloperText}
                        type="web developer"/>
                    <Fact 
                        text={context.mobileDeveloperText}
                        type="mobile developer" />
                    <Fact 
                        text={context.teamPlayerText}
                        type="team player" />
                </motion.div>
            </div>
        </div>
    );
};

export default AboutPage;
