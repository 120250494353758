import classes from "./ErrorPage.module.css";
import { Link } from "react-router-dom";

const ErrorPage = () =>
{
    return (
        <div className={classes.errorPageContainer}>
            <h2 className={classes.header}>4😟4</h2>
            <p className={classes.info}>Page Not Found :(</p>
            <p className={classes.text}>The Page You were Looking for doesn't Exists</p>
            <p className={classes.asterix}>*</p>
            <p className={classes.asterix}>*</p>
            <p className={classes.asterix}>*</p>
            <p className={classes.asterix}>*</p>
            <p className={classes.text}>Let's Get You Back</p>
            <p className={classes.asterix}>*</p>
            <p className={classes.asterix}>*</p>
            <p className={classes.last_asterix}>*</p>
            <Link className={classes.button} href="/">Let's Back to Main Page</Link>
        </div>
    );
}

export default ErrorPage;