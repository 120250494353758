import classes from "./ProjectDivContainer.module.css";

const ProjectDivContainer = (props) => {
    return (
        <div className={classes.ProjectDivContainer}>
            {props.children}
        </div>
    );
};

export default ProjectDivContainer;