const nullValidation = (states) =>
{
    for(let i=0;i<states.length;i++)
    {
        if (states[i].length === 0)
        {
            return false;
        }
    }
    return true;
};

export default nullValidation;
