import classes from "./Input.module.css";

const Input = (props) => {
    return (
        <input 
            className={classes.Input} 
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            onChange={props.onChange}
        />
    );
};

export default Input;