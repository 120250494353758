import classes from "./ContactButton.module.css";

const ContactButton = (props) =>{
    return (
        <button 
            className={classes.ContactButton} 
            type="submit">{props.text}</button>
    );
};

export default ContactButton;