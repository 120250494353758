import classes from "./CVPage.module.css";
import { useDispatch,useSelector } from "react-redux";
import { blogActions } from "../Store/BlogSlice";
import CVTitle from "../Components/CVPage/CVTitle";
import CVFeature from "../Components/CVPage/CVFeature";
import { motion } from "framer-motion";
import { useState,useEffect } from "react";
import TRCV from "../assets/CV/Mustafa_Doruk_Cil_CV_TR.pdf";
import ENCV from "../assets/CV/Mustafa_Doruk_Cil_CV_EN.pdf";

const ErrorPage = () =>
{
    const BlogLanguage = useSelector(state=>state.BlogLanguage);
    const TurkishLanguage = 
    {
        cvHeader: "Özgeçmiş",
        cvLinkName: "İndir",
        cvDownloadLink: TRCV,
        cvName: "Mustafa_Doruk_Cil_CV_TR.pdf",
        educationHeader: "Eğitim",
        educationFirstLine: "Lisans",
        educationSecondLine: "İstanbul Okan Üniversitesi",
        educationThirdLine: "(Devam Ediyor)",
        educationFourthLine: "Genel Not Ortalaması: 3.82",
        foreignLanguagesHeader: "Yabancı Diller",
        firstForeignLanguage: "İngilizce",
        secondForeignLanguage: "Almanca",
        programmingLanguagesHeader: "Programlama Dilleri",
        firstProgrammingLanguage: "Python",
        secondProgrammingLanguage: "Javascript",
        thirdProgrammingLanguage: "Node JS (Javascript)",
        fourthProgrammingLanguage: "Java",
        fifthProgrammingLanguage: "PHP",
        sixthProgrammingLanguage: "Dart",
        databaseHeader: "Database Bilgisi",
        firstDatabaseLine: "SQL",
        secondDatabaseLine: "Mongo DB",
        thirdDatabaseLine: "Firebase",
        workingAreaHeader: "Çalışma Alanı",
        workingAreaFirstLine: "Yazılım Mühendisliği",
        workingAreaSecondLine: "Web Programlama",
        workingAreaThirdLine: "Mobil Programlama",
        workingAreaFourthLine: "Siber Güvenlik",
        workingAreaFifthLine: "Arama Motoru Optimizasyonu",
        markupLanguagesHeader: "Biçimlendirme Dilleri",
        firstMarkupLanguage: "HTML",
        secondMarkupLanguage: "CSS",
        frameworkHeader: "Framework ve Kütüphane Bilgisi",
        firstFramework: "React",
        secondFramework: "Express JS",
        thirdFramework: "React Native",
        fourthFramework: "Electron JS",
        fifthFramework: "Flutter",
        sixthFramework: "Selenium",
        seventhFramework: "Numpy",
        eightFrameWork: "Pandas",
        operatingSystemsHeader: "İşletim Sistemleri",
        firstOperatingSystem: "Windows",
        secondOperatingSystem: "Linux",
        thirdOperatingSystem: "Android",
    };

    const variants = {
        upperInitial:{ y: -100, opacity: 0 },
        upperAnimate:{ y: 0, opacity: 1 },
        bottomInitial: { y: "100%", opacity: 0 },
        bottomAnimate: { y: 0, opacity: 1 },
        leftInitial: { x: "-100%", opacity: 0 },
        leftAnimate: { x: 0, opacity: 1 },
        rightInitial: { x: "100%", opacity: 0 },
        rightAnimate: { x: 0, opacity: 1 }
    };

    const [context,setContext] = useState(TurkishLanguage);
    const dispatch = useDispatch();
    const [isAnimated,setIsAnimated] = useState(false);
    
    useEffect(()=>{
        dispatch(blogActions.setCurrentPage("cv"));
        setIsAnimated(true);
        const timeoutId = setTimeout(() => {
            setIsAnimated(false);
        }, 300);
        if(BlogLanguage==="EN")
        {
            setContext(
                {
                    cvHeader: "CV",
                    cvLinkName:"Download",
                    cvDownloadLink: ENCV,
                    cvName: "Mustafa_Doruk_Cil_CV_EN.pdf",
                    educationHeader: "Education",
                    educationFirstLine: "Bachelor",
                    educationSecondLine: "Istanbul Okan University",
                    educationThirdLine: "(Continue)",
                    educationFourthLine: "GPA: 3.82",
                    foreignLanguagesHeader: "Foreign Languages",
                    firstForeignLanguage: "English",
                    secondForeignLanguage: "German",
                    programmingLanguagesHeader: "Programming Languages",
                    firstProgrammingLanguage: "Python",
                    secondProgrammingLanguage: "Javascript",
                    thirdProgrammingLanguage: "Node JS (Javascript)",
                    fourthProgrammingLanguage: "Java",
                    fifthProgrammingLanguage: "PHP",
                    sixthProgrammingLanguage: "Dart",
                    databaseHeader: "Database Knowledge",
                    firstDatabaseLine: "SQL",
                    secondDatabaseLine: "Mongo DB",
                    thirdDatabaseLine: "Firebase",
                    workingAreaHeader: "Fields of Study",
                    workingAreaFirstLine: "Software Engineering",
                    workingAreaSecondLine: "Web Programming",
                    workingAreaThirdLine: "Mobil Programming",
                    workingAreaFourthLine: "Cyber Security",
                    workingAreaFifthLine: "Search Engine Optimization",
                    markupLanguagesHeader: "Markup Languages",
                    firstMarkupLanguage: "HTML",
                    secondMarkupLanguage: "CSS",
                    frameworkHeader: "Framework and Library Knowledge",
                    firstFramework: "React",
                    secondFramework: "Express JS",
                    thirdFramework: "React Native",
                    fourthFramework: "Electron JS",
                    fifthFramework: "Flutter",
                    sixthFramework: "Selenium",
                    seventhFramework: "Numpy",
                    eightFrameWork: "Pandas",
                    operatingSystemsHeader: "Operating Systems",
                    firstOperatingSystem: "Windows",
                    secondOperatingSystem: "Linux",
                    thirdOperatingSystem: "Android",
                }
            );
        }
        else
        {
            setContext(TurkishLanguage);
        }

        return () => clearTimeout(timeoutId);

        // eslint-disable-next-line
    },[BlogLanguage,dispatch]);

    return (
        <div className={classes.CVPageDiv}>
            <div className={classes.CVPageInnerDiv}>
                <motion.h2 
                className={classes.CVPageHeader}
                initial={variants.upperInitial}
                animate={isAnimated ? variants.upperInitial : variants.upperAnimate}
                exit={variants.upperInitial}>
                {context.cvHeader} (<a 
                    className={classes.CVDownloadLink} 
                    href={context.cvDownloadLink} 
                    download={context.cvName}
                    target="_blank"
                    rel="noreferrer noopener">{context.cvLinkName}</a>)
                </motion.h2>
                <div className={classes.CVFeatureDivContainer}>
                    <motion.div 
                    className={classes.CVFeatureDiv}
                    initial={variants.leftInitial}
                    animate={isAnimated ? variants.leftInitial : variants.leftAnimate}
                    exit={variants.leftInitial}>
                        <CVTitle text={context.educationHeader} />
                        <CVFeature text={context.educationFirstLine} />
                        <CVFeature text={context.educationSecondLine} />
                        <CVFeature text={context.educationThirdLine} />
                        <CVFeature text={context.educationFourthLine} />

                        <CVTitle text={context.foreignLanguagesHeader} />
                        <CVFeature text={context.firstForeignLanguage} featureLevel="4" />
                        <CVFeature text={context.secondForeignLanguage} featureLevel="1" />

                        <CVTitle text={context.frameworkHeader} />
                        <CVFeature text={context.firstFramework} featureLevel="4" />
                        <CVFeature text={context.secondFramework} featureLevel="4" />
                        <CVFeature text={context.thirdFramework} featureLevel="3"/>
                        <CVFeature text={context.fourthFramework} featureLevel="3" />
                        <CVFeature text={context.fifthFramework} featureLevel="3" />
                        <CVFeature text={context.sixthFramework} featureLevel="3" />
                        <CVFeature text={context.seventhFramework} featureLevel="3" />
                       
                        <CVTitle text={context.databaseHeader} />
                        <CVFeature text={context.firstDatabaseLine} featureLevel="4" />
                        <CVFeature text={context.secondDatabaseLine} featureLevel="3" />
                        <CVFeature text={context.thirdDatabaseLine} featureLevel="2" />
                    </motion.div>

                    <motion.div 
                    className={classes.CVFeatureDiv}
                    initial={variants.rightInitial}
                    animate={isAnimated ? variants.rightInitial : variants.rightAnimate}
                    exit={variants.rightInitial}>
                        <CVTitle text={context.workingAreaHeader} />
                        <CVFeature text={context.workingAreaFirstLine} />
                        <CVFeature text={context.workingAreaSecondLine} />
                        <CVFeature text={context.workingAreaThirdLine} />
                        <CVFeature text={context.workingAreaFourthLine} />
                        <CVFeature text={context.workingAreaFifthLine} />

                        <CVTitle text={context.markupLanguagesHeader} />
                        <CVFeature text={context.firstMarkupLanguage} featureLevel="4" />
                        <CVFeature text={context.secondMarkupLanguage} featureLevel="3"/>

                        <CVTitle text={context.programmingLanguagesHeader} />
                        <CVFeature text={context.firstProgrammingLanguage} featureLevel="4" />
                        <CVFeature text={context.secondProgrammingLanguage} featureLevel="3" />
                        <CVFeature text={context.thirdProgrammingLanguage} featureLevel="3" />
                        <CVFeature text={context.fourthProgrammingLanguage} featureLevel="3" />
                        <CVFeature text={context.fifthProgrammingLanguage} featureLevel="2" />
                        <CVFeature text={context.sixthProgrammingLanguage} featureLevel="2" />


                        <CVTitle text={context.operatingSystemsHeader} />
                        <CVFeature text={context.firstOperatingSystem} featureLevel="5" />
                        <CVFeature text={context.secondOperatingSystem} featureLevel="3" />
                        <CVFeature text={context.thirdOperatingSystem} featureLevel="3" />
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;