import classes from "./FeatureLabel.module.css";

const FeatureLabel = (props) => {
    return (
        <div className={classes.FeatureDiv}>
            <span className={classes.FeatureIcon}>{"> "}</span>
            <p 
            className={classes.FeatureName}>
            {props.fieldName+": "} 
            <span className={classes.FeatureLabel}>{props.fieldValue}</span>
            </p>
        </div>
    );
};

export default FeatureLabel;