import classes from "./SocialMediaIcon.module.css";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";


const SocialMediaIcon = (props) => {
    let icon;
    let href;
    if(props.platform === "mail")
    {
        icon = <FaEnvelope className={classes.Icon}/>
        href = "mailto:mustafacil198@gmail.com";
    }
    else if(props.platform === "github")
    {
        icon = <FaGithub className={classes.Icon}/>
        href = "https://github.com/zThyphon";
    }
    else if(props.platform === "linkedin")
    {
        icon = <FaLinkedin className={classes.Icon}/>
        href = "https://www.linkedin.com/in/mustafa-doruk-%C3%A7il-602a33217/";
    }
    return (
        <a 
            className={classes.PlatformLink}
            href={href} 
            alt={props.platform}
            target= "_blank"
            rel="noreferrer noopener">{icon}
        </a>
    );
};

export default SocialMediaIcon;