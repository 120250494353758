import classes from "./Navbar.module.css";
import PersonelProfile from "../Elements/PersonelProfile";
import PersonelName from "../Elements/PersonelName";
import Row from "../UI/Row";
import LanguageBar from "../Elements/LanguageBar";
import SocialMediaIcon from "../Elements/SocialMediaIcon";
import PageIcon from "../Elements/PageIcon";
import BlogInfo from "../Elements/BlogInfo";
import { useSelector,useDispatch } from "react-redux";
import { blogActions } from "../../../Store/BlogSlice";

const Navbar = () =>{
    const BlogLanguage = useSelector(state=>state.BlogLanguage);
    const CurrentPage = useSelector(state=>state.CurrentPage);

    const dispatch = useDispatch();

    const setBlogLanguage = (language) => {
        dispatch(blogActions.setBlogLanguage(language));
    };

    return (
        <div className={classes.navbarDiv}>
            <PersonelProfile />
            <PersonelName />
            <Row>
                <LanguageBar 
                    language="TR" 
                    onClick={()=>{setBlogLanguage("TR")}}
                    active={BlogLanguage === "TR" && true}
                />
                <LanguageBar 
                    language="EN" 
                    onClick={()=>{setBlogLanguage("EN")}}
                    active={BlogLanguage === "EN" && true}
                />
            </Row>
            <Row>
                <SocialMediaIcon platform="mail" />
                <SocialMediaIcon platform="github" />
                <SocialMediaIcon platform="linkedin" />
            </Row>

            <PageIcon 
                pageName="home"
                active={CurrentPage === "home" && true} />
            <PageIcon 
                pageName="about"
                active={CurrentPage === "about" && true} />
            <PageIcon 
                pageName="cv"
                active={CurrentPage === "cv" && true} />
            <PageIcon 
                pageName="projects"
                active={CurrentPage === "projects" && true} />
            <PageIcon 
                pageName="contact"
                active={CurrentPage === "contact" && true} />
            <BlogInfo />
        </div>
    );
};

export default Navbar;
