import classes from "./BlogInfo.module.css";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const BlogInfo = () => {
    const BlogLanguage = useSelector(state=>state.BlogLanguage);
    let InfoDescription;
    if(BlogLanguage==="TR")
    {
        InfoDescription = <p className={classes.BlogInfo}>Bu Websitesi <span className={classes.Developer}>Mustafa Doruk ÇİL</span> Tarafından Geliştirilmiştir</p>
    }
    else
    {
        InfoDescription = <p className={classes.BlogInfo}>This Website Developed by <span className={classes.Developer}>Mustafa Doruk ÇİL</span></p>
    }
    return (
        <Fragment>
            <div className={classes.firstBlogInfoDiv}>
                {InfoDescription}
                <p className={classes.BlogInfo}>© 2024</p>
            </div>
        </Fragment> 
    );
};

export default BlogInfo;