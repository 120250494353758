import classes from "./CVFeature.module.css";

const CVFeature = (props) => {
    let featureLevel = "";
    if(props.featureLevel==="5")
    {
        featureLevel= " ● ● ● ● ● ";
    }
    else if(props.featureLevel==="4")
    {
        featureLevel= " ● ● ● ● ";
    }
    else if(props.featureLevel==="3")
    {
        featureLevel= " ● ● ● ";
    }
    else if(props.featureLevel==="2")
    {
        featureLevel= " ● ● ";
    }
    else if(props.featureLevel==="1")
    {
        featureLevel= " ● ";
    }
    
    return (
        <p className={classes.CVFeature}>
            {props.text}<span className={classes.FeatureLevel}> {featureLevel} </span></p>
    );
};

export default CVFeature;