import classes from "./HomePage.module.css";
import { useDispatch,useSelector } from "react-redux";
import { blogActions } from "../Store/BlogSlice";
import { Typewriter, Cursor } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { useEffect,useState } from "react";

const HomePage = (props) => {
    const BlogLanguage = useSelector(state=>state.BlogLanguage);
    const dispatch = useDispatch();
    const [info,setInfo] = useState("");
    const [jobs,setJobs] = useState(["Yazılım Mühendisi","Web Geliştirici","Mobil Uygulama Geliştirici"]);
    
    useEffect(()=>{
        dispatch(blogActions.setCurrentPage("home"));

        if(BlogLanguage === "EN")
        {
            setInfo("I'm a ");
            setJobs(["Software Engineer","Web App Developer","Mobile App Developer","Full Stack Developer"]);
        }
        else
        {
            setInfo("");
            setJobs(["Yazılım Mühendisi","Web Geliştirici","Mobil Uygulama Geliştirici"]);
        }
    }, [BlogLanguage,dispatch]);

    return (    
        <motion.div className={classes.HomePageDiv}
            variants= {{
                hidden: {opacity: 0},
                visible: {opacity: 1}
            }}
            initial= "hidden"
            animate= "visible"
            exit= "hidden">
            <div className={classes.CenterDiv}>
                <h2 className={classes.PersonelName}>Mustafa Doruk ÇİL</h2> 
                <p className={classes.PersonelInfo}>
                {info}<span className={classes.Job}>
                <Typewriter
                    words={jobs}
                    loop={Infinity}
                    cursor
                    cursorStyle=""
                    typeSpeed={50}
                    deleteSpeed={50}
                    delaySpeed={1500}
                    />
                </span>
                <Cursor />
                </p>
            </div>
        </motion.div>
    );
};

export default HomePage;