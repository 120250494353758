import classes from "./TextArea.module.css";

const TextArea = (props) => {
    return (
        <textarea 
            className={classes.TextArea}
            placeholder={props.placeholder}
            onChange={props.onChange}></textarea>
    );
};

export default TextArea;